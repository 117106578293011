import { createRouter, createWebHistory, RouteLocation, RouteRecordRaw } from 'vue-router';
import useUpdateChecker from '@/compositions/UseUpdateChecker';
import useLoadingState from '@/compositions/UseLoadingState';
import { useSession } from '@/compositions/UseSession';
import useAutoLogout from '@/compositions/UseAutoLogout';

export function safeParseInt(text: string | undefined | null) {
  if (!text) return 0;
  const value = parseInt(text);
  if (isNaN(value) || value < 0) return 0;
  return value;
}

// convert all the route params to numeric values because the vue component props are all numeric
const paramsToNumber = (route: RouteLocation) => {
  const params: { [key: string]: number | number[] } = {};
  Object.entries(route.params).forEach(val => {
    params[val[0]] = safeParseInt(val[1].toString());
  });
  return params;
};

const queryToProps = (route: RouteLocation) => {
  const params: { [key: string]: number | number[] | string } = {};
  if ('ids' in route.query && !!route.query.ids) {
    params['ids'] = String(route.query.ids).split(',').map(safeParseInt).filter(id => id > 0);
  }
  if ('statistic' in route.query && !!route.query.statistic) {
    params['statistic'] = String(route.query.statistic);
  }
  return params;
};

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/layouts/LoggedInLayout.vue'),
    children: [
      {path: '', component: () => import('@/routes/HomePage.vue')},
      {path: 'about', component: () => import('@/routes/AboutApp.vue')},
      {path: 'account', component: () => import('@/routes/MyAccount.vue')},
      {
        path: '/player',
        children: [
          {path: '', component: () => import('@/routes/Player/PlayerHome.vue')},
          {path: 'screening/start', component: () => import('@/routes/Player/StartScreening.vue')},
          {path: 'screening/:id', component: () => import('@/routes/Player/ViewScreening.vue'), props: paramsToNumber},
          {path: 'screening/:id/bhp', component: () => import('@/routes/Player/BhpAssessment.vue'), props: paramsToNumber},
          {path: 'screening/:id/bhp-end', component: () => import('@/routes/Player/BhpAssessmentEnd.vue'), props: paramsToNumber},
          {path: 'screening/:id/self', component: () => import('@/routes/Player/SelfAssessment.vue'), props: paramsToNumber},
          {path: 'screening/:id/self-end', component: () => import('@/routes/Player/SelfAssessmentEnd.vue'), props: paramsToNumber},
        ]
      },
      {
        path: '/bhp',
        children: [
          {path: '', component: () => import('@/routes/Bhp/BhpHome.vue')},
          {path: 'assessment/:screeningId', component: () => import('@/routes/Bhp/BhpAssessment.vue'), props: paramsToNumber},
          {path: 'player/:playerId', component: () => import('@/routes/Bhp/BhpPlayer.vue'), props: paramsToNumber},
        ]
      },
      {
        path: '/orgadmin',
        component: () => import('@/layouts/OrgAdminLayout.vue'),
        children: [
          {path: '', redirect: () => '/orgadmin/home'},
          {path: 'home', component: () => import('@/routes/OrgAdmin/OrgAdminHome.vue')},
          {path: 'activity', component: () => import('@/routes/OrgAdmin/OrgAdminActivity.vue')},
          {path: 'player', component: () => import('@/routes/OrgAdmin/OrgAdminPlayers.vue'), props: queryToProps},
          {path: 'player/:playerId', component: () => import('@/routes/OrgAdmin/OrgAdminPlayer.vue'), props: paramsToNumber},
          {path: 'user', component: () => import('@/routes/OrgAdmin/OrgAdminUsers.vue')},
          {path: 'user/:userId', component: () => import('@/routes/OrgAdmin/OrgAdminUser.vue'), props: paramsToNumber},
          {path: 'specialist', component: () => import('@/routes/OrgAdmin/OrgAdminSpecialists.vue')},
          {path: 'screening', component: () => import('@/routes/OrgAdmin/OrgAdminScreenings.vue'), props: queryToProps},
          {path: 'screening/:screeningId', component: () => import('@/routes/OrgAdmin/OrgAdminScreening.vue'), props: paramsToNumber},
          {path: 'settings', component: () => import('@/routes/OrgAdmin/OrgAdminSettings.vue')},
          {path: 'statistics', component: () => import('@/routes/OrgAdmin/OrgAdminStatistics.vue')},
        ]
      },
      {
        path: '/sysadmin',
        component: () => import('@/layouts/SysAdminLayout.vue'),
        children: [
          {path: '', redirect: () => '/sysadmin/home'},
          {path: 'home', component: () => import('@/routes/SysAdmin/SysAdminHome.vue')},
          {path: 'activity', component: () => import('@/routes/SysAdmin/SysAdminActivity.vue')},
          {path: 'user', component: () => import('@/routes/SysAdmin/SysAdminUsers.vue')},
          {path: 'user/:userId', component: () => import('@/routes/SysAdmin/SysAdminUser.vue'), props: paramsToNumber},
          {path: 'player', component: () => import('@/routes/SysAdmin/SysAdminPlayers.vue'), props: queryToProps},
          {path: 'player/:playerId', component: () => import('@/routes/SysAdmin/SysAdminPlayer.vue'), props: paramsToNumber},
          {path: 'screening', component: () => import('@/routes/SysAdmin/SysAdminScreenings.vue'), props: queryToProps},
          {path: 'screening/:screeningId', component: () => import('@/routes/SysAdmin/SysAdminScreening.vue'), props: paramsToNumber},
          {path: 'organisation', component: () => import('@/routes/SysAdmin/SysAdminOrganisations.vue')},
          {path: 'organisation/:organisationId', component: () => import('@/routes/SysAdmin/SysAdminOrganisation.vue'), props: paramsToNumber},
          {path: 'page', component: () => import('@/routes/SysAdmin/SysAdminPages.vue')},
          {path: 'page/import', component: () => import('@/routes/SysAdmin/SysAdminPagesImport.vue')},
          {path: 'page/:pageId', component: () => import('@/routes/SysAdmin/SysAdminPage.vue'), props: paramsToNumber},
          {path: 'scoringguides', component: () => import('@/routes/SysAdmin/SysAdminScoringGuides.vue')},
          {path: 'cron', component: () => import('@/routes/SysAdmin/SysAdminCronJobs.vue')},
          {path: 'purchaseorders', component: () => import('@/routes/SysAdmin/SysAdminPurchaseOrders.vue')},
          {path: 'statistics', component: () => import('@/routes/SysAdmin/SysAdminStatistics.vue')},
        ]
      }
    ],
  },
  {
    path: '/auth',
    children: [
      {path: 'login', component: () => import('@/routes/Auth/UserLogin.vue')},
      {path: 'register', component: () => import('@/routes/Auth/UserRegister.vue')},
      {path: 'lost-password', component: () => import('@/routes/Auth/LostPassword.vue')},
      {path: 'set-password', component: () => import('@/routes/Auth/SetPassword.vue')},
      {path: 'player-signup', component: () => import('@/routes/Auth/PlayerSignup.vue')},
      {path: 'auto-logged-out', component: () => import('@/routes/Auth/AutoLoggedOut.vue')},
      {path: 'one-click-login', component: () => import('@/routes/Auth/OneClickLogin.vue')},
      {
        path: ':pathMatch(.*)*',
        alias: ['/login', '/auth'],
        component: () => import('@/routes/Auth/AuthHome.vue')
      },
    ],
  },
  {
    path: '/external',
    children: [
      {path: 'supporter/:id', component: () => import('@/routes/External/SupporterReview.vue'), props: paramsToNumber},
      {path: 'doctor/:id', component: () => import('@/routes/External/DoctorReview.vue'), props: paramsToNumber},
    ],
  },
  {
    path: '/page',
    children: [
      {path: ':name([-.a-zA-Z0-9]+)', component: () => import('@/routes/PublicPage.vue'), props: true},
    ],
  },
  // Always leave this as last one: shows an error if the route is invalid
  {path: '/:catchAll(.*)*', component: () => import('@/routes/FourOhFour.vue'),},
];

const router = createRouter({
  scrollBehavior: () => ({left: 0, top: 0}),
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory('/'),
  routes,
});

function mustBeLoggedIn(url: string) : boolean {
  if (url === '/') return false;
  if (url.startsWith('/auth')) return false;
  if (url.startsWith('/external')) return false;
  if (url.startsWith('/page')) return false;
  return true;
}

const anyUserCanAccess = ['/about', '/account'];
const {activityDetected} = useAutoLogout();

router.beforeEach((to) => {
  if (!mustBeLoggedIn(to.path)) {
    // if anyone can go there, just go there
    return true;
  }
  const session = useSession();
  if (!session.isLoggedIn) {
    // redirect to login if not authed
    return '/auth';
  }
  // if the user tries to go to the wrong place, redirect them to the right place
  const allowedBaseUrl = session.getRoleBaseUrl();
  const goingToWrongPlace = allowedBaseUrl && !to.path.startsWith(allowedBaseUrl);
  if (goingToWrongPlace && !anyUserCanAccess.includes(to.path)) {
    return allowedBaseUrl;
  }

  activityDetected();

  // changed page, don't care about current ajax queries
  useLoadingState().clear();

  // this is a simple activity checker: if the user is moving around the app then we know to check for updates
  useUpdateChecker().checkForUpdate(); // could await but we don't care as long as the promise resolves sometime
});

export default router;
