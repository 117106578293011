/**
 * strictly type and validate environment variables
 */
import process from 'process';
import { URI } from 'vscode-uri';

type EnvironmentName = 'production' | 'nonprod' | 'koandev' | 'test' | 'development';

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      NODE_ENV: EnvironmentName | undefined;
      BUILD_NUMBER: string | undefined;
      // VUE_ROUTER_MODE: 'hash' | 'history' | 'abstract' | undefined;
      // VUE_ROUTER_BASE: string | undefined;
      // API: string; // url to brain health api for axios
      // SENTRY_DSN: string;
    }
  }
}

const apiEndpoints: { [env in EnvironmentName]: string } = {
  'koandev': 'https://api.koandev.brainhealth-koan.co.nz',
  'nonprod': 'https://api.nonprod.brainhealthservice.rugby',
  'production': 'https://api.brainhealthservice.rugby',
  'development': 'http://localhost:8080',
  'test': 'http://localhost:8080'
};

// call /auth/login?_cypress=1 to set a flag for cypress
// we should not use this very much: its a one-off convenience for testing so we don't have to complete player and supporter assessments all the way through just to test a small feature at the end
export function setIsCypress(is: boolean = true) {
  if (is) {
    sessionStorage.setItem('isCypress', 'true');
  } else {
    sessionStorage.removeItem('isCypress');
  }
}

export const Environment = {
  // these are used to switch things on and off eg as feature flags
  isProduction: () => Environment.getEnvironment() === 'production',
  isDevelopment: () => Environment.getEnvironment() === 'development',
  isUnitTest: () => Environment.getEnvironment() === 'test' || window.location.hostname === 'localhost',
  isCypress: () => !Environment.isProduction() && sessionStorage.getItem('isCypress') === 'true',
  baseUrl: () => window.location.origin,
  getEnvironment: () => import.meta.env?.MODE ?? 'development',
  getEmailDomain: () => (new URL(Environment.getAPI())).hostname,
  getBuildDate: () => BUILD_DATE ?? (new Date()).toISOString(),
  getBuildNumber: () => BUILD_NUMBER ?? '0',
  getSentryDSN: () => import.meta.env?.SENTRY_DSN ?? 'https://04609ef5708b43b0a767a255ad0e40dd@o4503915914657792.ingest.sentry.io/4505145710477312',
  getAPI: () => import.meta.env?.API ?? apiEndpoints[Environment.getEnvironment() as EnvironmentName] ?? apiEndpoints['development'],
  getAPITimeout: () => Environment.isUnitTest() ? 4000 : 20000
};
